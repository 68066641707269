import { useState, useEffect } from "react";

// Si largeur fenetre inférieur ou egale a 740 px renvoie true.
const useMobile = () => {
	const [isMobile, setIsMobile] = useState(
		document.body.clientWidth <= 740
	);

	const handleResize = () => {
		setIsMobile(document.body.clientWidth <= 740);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		// Remove au démontage (très important)
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isMobile;
};

export default useMobile;
