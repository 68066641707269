import React from "react";
import '@justinribeiro/lite-youtube';
import logoMobile from "../assets/images/parent-head-logo.png";
import logo from "../assets/images/bulle_header_parents.png";
import { useNavigate } from "react-router-dom";
import { useUser } from '../Hook/useUser';


function HeaderParent() {
	const navigate = useNavigate();
	const { user } = useUser();

	const goToAllArticleWithTag = (value) => {
		if (user === "parent") {
			navigate("/Parents/AllArticles", { state: { tag: value} });
		} else {
			navigate("/Relais/Blog", { state: { tag: value} });
		}		
	};

	return (
		<div className="headerParent">
			<div className="headerParent__left-container">
                <img className="headerParent__desktop-img" src={logo} alt="Enfin un site pour savoir de quoi on parle" width="700" height="468" />
                <p className="headerParent__left-container-text">
					Vous êtes au contact d’enfants et vous souhaitez que l’on vous accompagne pour aborder les règles facilement et de manière ludique ? Vous êtes au bon endroit ! Bienvenue sur Parlons Règles, ici vous trouverez des ressources clés en main pour parler de règles sans tabou.
                </p>
			</div>
			<div className="headerParent__left-container-mobile">
				<div className="headerParent__logo-container">
					<img src={logoMobile} alt="" aria-hidden="true" width="600" height="191" />
				</div>
			</div>
			<div className="headerParent__video-container">
				<lite-youtube class="young-head__video" videoid="PpnJh-LZFMw" videoplay="Voir" videotitle="Les règles c'est sale ?" posterquality="maxresdefault" autopause>
					<a href="https://www.youtube.com/watch?v=PpnJh-LZFMw">Voir sur YouTube: "Les règles c'est sale ?"</a>
				</lite-youtube>
				<div className="headerParent__sub-video-container">
					<div className="headerParent__tag-container">
						<button className="headerParent__tag focused" onClick={() => goToAllArticleWithTag("Tabou")}>#Tabou</button>
					</div>
					<p className="headerParent__video-title">« Les règles, c’est sale » : un cliché vieux comme l’histoire</p>
				</div>
			</div>
		</div>
	);
}

export default HeaderParent;
