const calculateScore = (categories, categoryButton) => {
  let score = 0;
  for (let i = 0; i < categories.length; i++) {
    const index = categoryButton.indexOf(categories[i]);
    if (index !== -1) {
      score += categoryButton.length - index;
    }
  }
  return score;
};

const sortByPreferences = (listData, TabCategory) => {
  listData.sort((a, b) => {
    const aCategories = a.category.map(item => item.name);
    const bCategories = b.category.map(item => item.name);
    const scoreA = calculateScore(aCategories, TabCategory);
    const scoreB = calculateScore(bCategories, TabCategory);
    return scoreB - scoreA;
  });
  return listData;
};
export const orderList = (listData, selectedCategory) => {
  const result = sortByPreferences([...listData], selectedCategory);
  return result;
};
