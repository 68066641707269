import React from "react";
import '@justinribeiro/lite-youtube';
import logoMobile from "../assets/images/parent-head-logo.png";
import logo from "../assets/images/bulle_header_parents.png";
import { useNavigate } from "react-router-dom";
import { useUser } from '../Hook/useUser';


function HeaderParent() {
	const navigate = useNavigate();
	const { user } = useUser();

	const goToAllArticleWithTag = (value) => {
		if (user === "parent") {
			navigate("/Parents/AllArticles", { state: { tag: value} });
		} else {
			navigate("/Relais/Blog", { state: { tag: value} });
		}		
	};

	return (
		<div className="headerParent">
			<div className="headerParent__left-container">
                <img className="headerParent__desktop-img" src={logo} alt="Enfin un site pour savoir de quoi on parle" width="700" height="468" />
                <p className="headerParent__left-container-text">
					Pas facile d’aborder le sujet des règles avec ses enfants… Quel est le bon moment ? À quel âge je peux commencer à en parler ? Avec quels mots ? Cette plateforme est conçue pour donner de l’information aux plus jeunes mais aussi pour accompagner les parents qui peuvent se poser autant de questions que leurs enfants !
                </p>
			</div>
			<div className="headerParent__left-container-mobile">
				<div className="headerParent__logo-container">
					<img src={logoMobile} alt="" aria-hidden="true" width="600" height="191" />
				</div>
			</div>
			<div className="headerParent__video-container">
				<lite-youtube class="headerParent__iframe" videoid="XwIC5C0WVJg" videoplay="Voir" videotitle="Parler de règles en famille" posterquality="maxresdefault" autopause>
					<a href="https://www.youtube.com/watch?v=XwIC5C0WVJg">Voir sur YouTube: "Parler de règles en famille"</a>
				</lite-youtube>
				<div className="headerParent__sub-video-container">
					<div className="headerParent__tag-container">
						<button className="headerParent__tag focused" onClick={() => goToAllArticleWithTag("Tabou")}>#Tabou</button>
					</div>
					<p className="headerParent__video-title">Parler des règles en famille</p>
				</div>
			</div>
		</div>
	);
}

export default HeaderParent;
