import React, { useEffect } from "react";
import AtelierOrganise from "../../components/AtelierOrganise";
import HeaderRelai from "../../components/HeaderRelai";
import PartnersFooter from "../../components/PartnersFooter";
import WhiteBookRelai from "../../components/WhiteBookRelai";
import SwapArticle from "../../components/SwapArticle";

function HomeRelay() {
    useEffect(() => {
      // set page title
      document.title = 'Accueil Relais - Parlons Règles';

      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', "Vous êtes au contact d’enfants et vous souhaitez que l’on vous accompagne pour aborder les règles facilement et de manière ludique ? Vous êtes au bon endroit ! Bienvenue sur Parlons Règles, ici vous trouverez des ressources clés en main pour parler de règles sans tabou.");
      }
    }, []);

    return (
      <div className="HomeRelay">
          <HeaderRelai />
          <WhiteBookRelai />
          <SwapArticle locate="Relais" />
          <AtelierOrganise />
          <PartnersFooter />
      </div>
    );
  }

export default HomeRelay;