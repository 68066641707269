import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

function SearchBar() {
	const [inputValue, setInputValue] = useState("");
	const navigate = useNavigate();

	// Gère le changement de la valeur de l'input utilisateur
	const UserValue = (event) => {
		setInputValue(event.target.value);
	};

	// Changement de chemin avec la valeur de l'input utilisateur ou une valeur pré-définie
	const routeChange = (value) => {
		let path = `/Jeunes/Resultats`;
		navigate(path, { state: { userInput: value || inputValue } });
	};

	// Gère le clic sur le bouton de validation
	const Validation = (event) => {
		event.preventDefault();
		routeChange();
	};

	return (
		<form
			className="young-head__searchbar-form"
			onSubmit={(e) => Validation(e)}
		>
			<input
				className="young-head__input"
				type="sumbit"
				value={inputValue}
				onChange={UserValue}
				placeholder=""
			/>
			<button className="young-head__send-button" onClick={Validation}>
				<HiOutlineArrowNarrowRight className="young-head__send-button-ico" />
			</button>
		</form>
	);
}

export default SearchBar;
