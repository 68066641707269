import React, { useState, useEffect } from 'react';
import useAxios from '../../Hook/useAxios';
import { useLocation } from "react-router-dom";
import VideoContainer from '../../components/VideoContainer';
import Filter from '../../components/Filter';
import { FaChevronRight } from "react-icons/fa";
import SendQuestionContainer from '../../components/SendQuestionContainer';
import allOnPeriodHeader from "../../assets/images/Logo-allOnPeriod.png";
import { SpecificIds } from '../../utilities/SpecificsIdVideo';
import { orderList } from '../../utilities/Order';
import ChatbotPopup from "../../components/ChatbotPopup";

function AllOnPeriods() {
  const data = useAxios("/videos?populate=*");
  const [allVideos, setAllVideos] = useState([]);
  const [videosToShow, setVideosToShow] = useState(6);
  const [selectedValues, setSelectedValues] = useState([]);
  const orderedVideos = orderList(allVideos, selectedValues);
  const specificVideo = SpecificIds(allVideos);
  const location = useLocation();

  const handleFilterChange = (values) => {
    setSelectedValues(values);
  };

  useEffect(() => {
    // set page title
    document.title = 'Vidéos sur les règles - Parlons Règles';

    const metaDescriptionTag = document.querySelector('meta[name="description"]');
		if (metaDescriptionTag) {
			metaDescriptionTag.setAttribute('content', "Ici tu vas en apprendre plus sur les règles, ce phénomène complètement naturel qui concerne des millions de personnes ! Qu'est-ce que c'est exactement, comment ça fonctionne, est-ce que ça fait mal, ou même est-ce que je peux faire du sport quand j'ai mes règles ??");
		}

    // on setup SelectedValues si on a un params d'url pour activer le tag si on on arrive sur cette page via un tab button
    if (location.state) {
      setSelectedValues(location.state.tag);
    }
    const getDataVideo = () => {
      if (data) {
        const allVideosData = data.data.data.map(item => ({
          id: item.id,
          title: item.attributes.title,
          url: item.attributes.url,
          desc: item.attributes.description,
          push_first: item.attributes.push_first,
          category: item.attributes.categories.data.map(category => ({
            name: category.attributes.name,
            visible: category.attributes.visible
          }))
        }));
        setAllVideos(allVideosData);
      }
    };
    getDataVideo();
  }, [data]);

  const handleShowMoreVideos = () => {
    setVideosToShow(prevCount => prevCount + 3);
  };

  const filteredVideos = selectedValues.length > 0 ? orderedVideos : specificVideo;
  const videosToDisplay = filteredVideos.slice(0, videosToShow);

  return (
    <div className="allOnPeriods">
      <div className="allOnPeriods__headerPage">
        <h1 className='allOnPeriods__title'>Videos sur les <strong><i>règles</i></strong></h1>
        <p className='allOnPeriods__paragraph'>
        Ici tu vas en apprendre plus sur les règles, ce phénomène complètement naturel qui concerne des millions de personnes ! Qu'est-ce que c'est exactement, comment ça fonctionne, est-ce que ça fait mal, ou même est-ce que je peux faire du sport quand j'ai mes règles ?? 
        </p>
        <div className='allOnPeriods__header-logo-container'>
          <img className='allOnPeriods__header-logo' src={allOnPeriodHeader} alt="une goutte de sang avec des jambes et une casquette" />
        </div>
      </div>
      <div className="allOnPeriods__videos">
        <div className='allOnPeriods__filter-container'>
          <Filter onFilterChange={handleFilterChange} />
        </div>
        <VideoContainer ListVideo={videosToDisplay} />
        {videosToShow < filteredVideos.length && (
          <button className='allOnPeriods__videos-button' onClick={handleShowMoreVideos}>
            Voir plus de vidéos <FaChevronRight className="allOnPeriods__videos-button-ico" />
          </button>
        )}
      </div>
      <SendQuestionContainer />
      <ChatbotPopup />
    </div>
  );
}

export default AllOnPeriods;