import { createContext, useState, useContext, useMemo } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import useMobile from './UseMobile';
import DOMPurify from 'dompurify';
import goutteQuestionRed from "../assets/images/goutteQuestionRed.svg";
import { IoCloseOutline } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa6";
import cupSunlight from "../assets/images/cup_sunlight.png";
import { GoCheckCircle } from "react-icons/go";
import { FaInstagram } from "react-icons/fa";
import redLogo from "../assets/images/logo-parlons-regles.png";

// Si on est sur une URL set le context sur jeunes || Parent || Relais
// Permet d'éviter lors du rechargement d'avoir un context young par defaut
const initUser = () => {
	const location = window.location.href;
	if (location.includes('Parents') || location.includes('article')) {
		return "parent";
	}
	if (location.includes('/Relais')) {
		return "relai";
	} else {
		return "young";
	}
}

// init context
const UserContext = createContext({ user: initUser, updateUser: () => {} });

// Create provider
export function UserContextProvider({ children }) {
	const [user, setUser] = useState(initUser);
	const [modalQuestionIsOpen, setModalQuestionIsOpen] = useState({
		formModal: false,
		thanksModal: false,
		chatbotModal: false
	});

	const value = useMemo(() => ({
		user,
		updateUser: (newUser) => setUser(newUser),
		modalQuestionIsOpen,
		setModalQuestionIsOpen: (newModal) => setModalQuestionIsOpen(newModal),
	  }), [user, modalQuestionIsOpen]);

	const [question, setQuestion] = useState("");
	const isMobile = useMobile();

	// Cette function permet de choisir la couleur de fond des modals
	const chooseBackground = () => {
		if (user === "parent") {
			return "linear-gradient(175deg, #9129ff 7.04%, #E8D9FF 86.23%)";
		} else if (user === "young" && modalQuestionIsOpen.formModal) {
			return "#ffb800";
		} else {
			return "#9129ff";
		}
	};
	const mobileStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			width: "100%",
			height: "100%",
			position: "absolute",
			top: "0",
			left: "0",
			right: "0",
			bottom: "",
			border: "none",
			background: chooseBackground(),
			padding: "20px",
			borderRadius: 0,
			Zindex: 999,
		},
	};
	const desktopStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: window.innerHeight <= 750 ? "100%" : "50%",
			height: window.innerHeight <= 750 ? "100%" : "70%",
			maxWidth: window.innerHeight <= 750 ? "none" : "1000px",
			position: "absolute",
			top: window.innerHeight <= 750 ? "0%" : "15%",
			left:window.innerHeight <= 750 ? "0%" : "25%",
			right: "0",
			bottom: "",
			border: "none",
			background: chooseBackground(),
			padding: "50px",
			borderRadius: window.innerHeight <= 750 ? "0px" : "20px",
			zIndex: 999,
		},
	};
	const chatbotStyle = {
		overlay: {
			zIndex: "999",
		},
		content: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			height: "100%",
			position: "absolute",
			top: "0",
			left: "0",
			right: "0",
			bottom: "",
			border: "none",
			background: "#FFF",
			padding: "20px",
			borderRadius: 0,
			Zindex: 999,
		},
	};

	const axiosConfig = {
		headers: {
			Authorization: `Bearer fadf5567ec16976a942a54abf71ab925fcef1080c66feb4db64ef7b5c06d582b02408ca81c159b41ff062da6c576b3198605f24d81ebc572f851c7e8d25d32b6eb8f7045898c468b1db7fb204592e9f1bbcd0bf600d3efa21f1f0331ac45158bd3901b3840efb1fb8f2ff6e0a47467259eb7edb73ee07dccf3e6cab0989a8461`,
		},
	};
	const closeModal = () => {
		setModalQuestionIsOpen({ formModal: false, thanksModal: false, chatbotModal: false });
	};
	const sendQuestion = () => {
		// DomPurify permet de secure les XSS et les injections SQL.
		const sanitizedQuestion = DOMPurify.sanitize(question);
		const data = {
			data: {
				question: sanitizedQuestion,
				answer: "",
				push_first: false,
				publishedAt: null,
				categories: [user === "young" ? 1 : 14]
			},
		};
		axios
			.post(
				"https://admin.parlonsregles.fr/api/testimonies",
				data,
				axiosConfig
			)
			.then(function (response) {
				console.log("response", response);
			})
			.catch(function (error) {
				console.log("error", error);
			});
		setModalQuestionIsOpen({ formModal: false, thanksModal: true });
	};
	return <UserContext.Provider value={value}>
		{children}

		<Modal
			isOpen={modalQuestionIsOpen.formModal}
			onRequestClose={closeModal}
			contentLabel="Poser une question"
			shouldCloseOnOverlayClick={true}
			ariaHideApp={false}
			style={isMobile ? mobileStyle : desktopStyle}
		>
			<div className="questionModal">
				<IoCloseOutline
					className="choosePref__modal-close-button"
					onClick={closeModal}
				/>
				<p className="questionModal__title">
					Tu n'as pas trouvé l'info que tu cherchais ?					
				</p>
				<textarea
					className="questionModal__textarea"
					onChange={(e) => setQuestion(e.target.value)}
					name=""
					placeholder="Pose ta question ici ..."
					id=""
					cols="75"
					rows="5"
				></textarea>
				<div className="questionModal__triangle"></div>
				<img
					className="questionModal__img"
					src={goutteQuestionRed}
					alt=""
					aria-hidden="true"
				/>
				<button
					disabled={question.length <= 0}
					className={`focused questionModal__send-button--${
						question.length === 0 ? "inactif" : "actif"
					}`}
					onClick={sendQuestion}
				>
					Envoyer{" "}
					<FaChevronRight className="choosePref__right-chevron" />
				</button>
				<p className="questionModal__remark">
					* On ne pourra pas te répondre directement mais on ajoutera la réponse sur le site prochainement.<br/>
					On ne peut malheureusement pas répondre aux questions trop personnelles ou médicales. 
				</p>
			</div>
		</Modal>
		<Modal
			isOpen={modalQuestionIsOpen.thanksModal}
			onRequestClose={closeModal}
			contentLabel="Confirmation de réception de la question"
			shouldCloseOnOverlayClick={true}
			ariaHideApp={false}
			style={isMobile ? mobileStyle : desktopStyle}
		>
			<IoCloseOutline
				className="choosePref__modal-close-button"
				onClick={closeModal}
			/>
			<div className="thanks-modal">
				<GoCheckCircle  className="thanks-modal__check-img"/>
				<p className="thanks-modal__title">Message bien reçu !</p>
				<p className="thanks-modal__text">
					Rejoins nous sur <strong>Instagram</strong>, on essaye de répondre aux nouvelles questions chaque semaine !
				</p>
				<a className="thanks-modal__link-tiktok" href="https://www.instagram.com/regleselementaires/" target="_blank" rel="noreferrer">
					<FaInstagram className="thanks-modal__tiktok" />
				</a>
				<img
					className="choosePref__cup-img"
					src={cupSunlight}
					alt="une cup avec des lunettes de soleil"
				/>
			</div>
		</Modal>
		<Modal
			isOpen={modalQuestionIsOpen.chatbotModal}
			onRequestClose={closeModal}
			contentLabel="Chatbot Parlons Règles"
			shouldCloseOnOverlayClick={true}
			ariaHideApp={false}
			style={chatbotStyle}
		>
			<div className="chatbotPopup__header">
				<img
					className="chatbotPopup__logo"
					src={redLogo}
					alt="Logo Parlons Règles"
				/>
				<IoCloseOutline
					className="chatbotPopup__modal-close-button"
					onClick={closeModal}
				/>
			</div>
			<iframe className="chatbotPopup__iframe" title="chatbot" src="https://parlonsregles.vercel.app/"></iframe>
		</Modal>
	</UserContext.Provider>;
}

// Custom Hook for interface context (consummer)
export function useUser() {
	return useContext(UserContext);
}
