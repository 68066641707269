import React from "react";
import { Link } from "react-router-dom";
import atelierRe from "../assets/images/AtelierRE.jpg";
import useMobile from "../Hook/UseMobile";
import { FaChevronRight } from "react-icons/fa6";

function AtelierOrganise() {
	const isMobile = useMobile();

	return (
		<div className="atelier">
			<div className="atelier__text-container">
				<h2 className="atelier__title">
					Organiser{" "}
					<strong>
						<i>un atelier</i>
					</strong>
				</h2>
				<p className="atelier__text">
					“La première fois que j’ai eu mes règles je ne savais pas ce qu’il se passait”. Bon nombre de personnes, lorsqu’elles ont leurs règles pour la première fois, n’ont pas les informations nécessaires pour comprendre ce qui leur arrive. À la fois enjeu d’égalité filles-garçons et de santé sexuelle et reproductive, l’éducation à la santé menstruelle est essentielle.
				</p>
				<p className="atelier__text">
					Ces dernières années, notre association, Règles Élémentaires a développé des ateliers qui s’adressent à tou·tes les élèves, filles comme garçons, dès le CM1.
				</p>
				{!isMobile && (
					<Link to="https://www.regleselementaires.com/agir/ecole/#sensibiliser" className="atelier__button focused" target="_blank" rel="noreferrer">
						J'organise un atelier{" "}
						<FaChevronRight className="partners-footer__chevron" />
					</Link>
				)}
			</div>
			<div className="atelier__img-container">
				<img className="atelier__img" src={atelierRe} alt="Une intervenante qui parle du cycle menstruel" />
			</div>
			{isMobile && (
				<Link to="https://www.regleselementaires.com/agir/ecole/#sensibiliser" className="atelier__button">
					J'organise un atelier{" "}
					<FaChevronRight className="partners-footer__chevron" />
				</Link>
			)}
		</div>
	);
}

export default AtelierOrganise;
