import { createContext, useState, useContext, useMemo } from "react";

const initValue = {
	age: "8",
	period: "notYet",
	tags: [],
};

const init = () => {
	if (localStorage.getItem("pref") === null) {
		return initValue;
	} else {
		const localData = localStorage.getItem("pref");
		return JSON.parse(localData);
	}
};

// init context
const prefContext = createContext(init(), () => {});

// Create provider
export function PrefContextProvider({ children }) {
	const [pref, setPref] = useState(init());
	const value = useMemo(() => ({
		pref,
		updatePref: (newPref) => {
		  localStorage.setItem("pref", JSON.stringify(newPref));
		  setPref(newPref);
		},
	  }), [pref]);
	return (
		<prefContext.Provider value={value}>{children}</prefContext.Provider>
	);
}

// Custom Hook for interface context (consummer)
export function usePref() {
	return useContext(prefContext);
}
