import React, { useState } from "react";
import whiteBook from "../assets/images/mockup-guide.png";
import { FaChevronRight } from "react-icons/fa6";
import Modal from "react-modal";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import useMobile from "../Hook/UseMobile";

function WhiteBookRelai() {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [emailSend, setEmailSend] = useState(false);

    const isMobile = useMobile();

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const modalStyleDesktop = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: "50%",
			height: "fit-content",
			top: "15%",
			left: "25%",
			right: "0",
			bottom: "",
			border: "none",
			background:
				"linear-gradient(-30deg, #9129ff 20%, #E8D9FF 90%)",
			padding: "50px",
			borderRadius: "20px",
			zIndex: 999,
		},
	};

    const modalStyleMobile = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: "100%",
			height: "100%",
			top: "0",
			left: "0",
			right: "0",
			bottom: "",
			border: "none",
			background:
				"linear-gradient(-30deg, #9129ff 20%, #E8D9FF 100%)",
			padding: "10px",
			borderRadius: "0px",
			zIndex: 999,
		},
	};

	const submitEmail = (e) => {
		const axiosConfig = {
			headers: {
				Authorization: `Bearer fadf5567ec16976a942a54abf71ab925fcef1080c66feb4db64ef7b5c06d582b02408ca81c159b41ff062da6c576b3198605f24d81ebc572f851c7e8d25d32b6eb8f7045898c468b1db7fb204592e9f1bbcd0bf600d3efa21f1f0331ac45158bd3901b3840efb1fb8f2ff6e0a47467259eb7edb73ee07dccf3e6cab0989a8461`,
			},
		};

		e.preventDefault();
		const data = {
			data: {
				email: e.target[0].value,
			},
		};
		axios
			.post(
				"https://admin.parlonsregles.fr/api/contacts",
				data,
				axiosConfig
			)
			.then(function (response) {
				console.log("response", response);
				setEmailSend(true);
			})
			.catch(function (error) {
				console.log("error", error);
				setEmailSend(true); // Allow download even if there is an error with the email entered (already in db for example)
			});
	};

	return (
		<div className="whiteBook">
			<div className="whiteBook__left-container">
				<h2 className="whiteBook__title">
					Parlons règles
					<strong>
						<i> à l'école</i>
					</strong>
				</h2>
				<p className="whiteBook__text">
					Vous souhaitez aborder la question des règles mais vous ne savez pas quand et comment ? En classe ou individuellement ? Vous voulez monter un projet dans votre établissement autour des règles ?
				</p>
				<p className="whiteBook__text">
					Cette plateforme est conçue pour donner de l’information aux plus jeunes mais aussi pour donner des conseils aux relais éducatifs (enseignant·es, AED, AESH, CPE, animateur·ices…)  pour aborder la question des règles.
				</p>
				<p className="whiteBook__text">
					“Parlons règles à l’école” est le premier guide pour repérer, anticiper les situations et accompagner les jeunes dans la connaissance de leur propre corps, ainsi que du corps de l’autre, pour favoriser le mieux être et le vivre ensemble.
				</p>
			</div>
			<div className="whiteBook__right-container">
				<div className="whiteBook__img-container">
					<img className="whiteBook__img" src={whiteBook} alt="" aria-hidden="true" />
				</div>
				<button
					className="whiteBook__download-button focused"
					onClick={() => setModalIsOpen(true)}
				>
					Télécharger le guide {" "}
					<FaChevronRight className="partners-footer__chevron" />
				</button>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					contentLabel="Téléchargement brochure"
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
					style={isMobile ? modalStyleMobile : modalStyleDesktop}
				>
					<div className="whiteBook__modal-content-container">
						{!emailSend && (
							<>
                            		<IoCloseOutline
									className="choosePref__modal-close-button"
									onClick={() => setModalIsOpen(false)}
								/>
								<p className="whiteBook__modal-title">
									Pour télécharger le guide merci de
									bien vouloir indiquer votre adresse e-mail*
								</p>
								<form
									className="whiteBook__form"
									action=""
									onSubmit={(e) => submitEmail(e)}
								>
									<input
										className="whiteBook__email-input"
										type="email"
										placeholder="E-mail"
									/>
									<button
										className="whiteBook__form-button"
										type="submit"
									>
										Envoyer{" "}
										<FaChevronRight className="partners-footer__chevron" />
									</button>
								</form>
								<p className="whiteBook__modal-text">
									*En téléchargeant le guide vous acceptez que Règles Élémentaires puisse vous recontacter dans la cadre de sa politique de confidentialité.
								</p>
							</>
						)}
						{emailSend && (
							<>
								<IoCloseOutline
									className="choosePref__modal-close-button"
									onClick={() => setModalIsOpen(false)}
								/>
								<a className="whiteBook__download-button focused" href="https://doccollectes.blob.core.windows.net/parlons-regles/guide_parlons_regles_a_lecole.pdf" target="_blank" rel="noreferrer">Télécharger le guide</a>
							</>
						)}
					</div>
				</Modal>
			</div>
		</div>
	);
}

export default WhiteBookRelai;
