import { usePref } from '../Hook/usePref';
import { orderList } from './Order';

const filterData = (listData, period) => {
    const filteredId = listData.filter(data =>
      data.category.some(category => category.name === period)
    );
    const restId = listData.filter(data =>
        !data.category.some(category => category.name === period)
    );
    return { filteredId, restId };
};

const SpecificIds = (listData) => {
    const pref = usePref();
    const age = pref.pref.age;
    const period = pref.pref.period;
    const tags = pref.pref.tags;

    const { filteredId, restId } = (() => {
        if (period === 'false') {
            return filterData(listData, 'notMenstruate');
        } else if (period === 'notYet' || (period === 'true' && age <= 11)) {
            return filterData(listData, 'notYet');
        } else {
            return filterData(listData, tags);
        }
    })();

    const orderedRestData = orderList(restId, tags);
    const preFilteredId = [...filteredId, ...orderedRestData];
    return preFilteredId;
};

export { SpecificIds };
