import React from "react";
import { useNavigate } from "react-router-dom";
import '@justinribeiro/lite-youtube';
import imgMobile from "../assets/images/bulle_home_mobile.png";
import imgDestkop from "../assets/images/bulle_home_desktop.png";
import useMobile from "../Hook/UseMobile";

function YoungHeader() {
	const navigate = useNavigate();
    const isMobile = useMobile();

	const goToResult = (value) => {
		navigate("/Jeunes/Resultats", { state: { userInput: value } });
	};

	const goToAllOnPeriodsWithTag = (value) => {
		navigate("/Jeunes/ToutSurLesRegles", { state: { tag: value} });
	};

	const goToAllOnPeriods = () => {
		navigate("/Jeunes/ToutSurLesRegles");
	};

	return (
		<div className="young-head">
			<div className="young-head__searchbar-container">
				<img 
					className="young-head__img" 
					src={isMobile ? imgMobile : imgDestkop} 
					alt="Salut, ici on parle de règles sans gêne. Quels sujets t’intéressent ?" 
					width={isMobile ? 322 : 800} 
					height={isMobile ? 119 : 347} 
				/>
                {!isMobile &&(
                    <div className="young-head__question-desktop-container">
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Douleurs")}>#douleurs</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Sport")}>#sport</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Tabou")}>#tabou</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Protections")}>#protections</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Cycles")}>#cycles</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToAllOnPeriods()}>Voir tout</button>
                    </div>
                )}
			</div>
			{isMobile && (
				<div className="young-head__most-question-container">
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToResult("Douleurs")}>#douleurs</button>
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToResult("Sport")}>#sport</button>
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToResult("Tabou")}>#tabou</button>
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToResult("Protections")}>#protections</button>
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToResult("Cycles")}>#cycles</button>
					<button tabIndex="0" className="young-head__question-mobile focused" onClick={() => goToAllOnPeriods()}>Voir tout</button>
				</div>
			)}
			<div className="young-head__video-container">
				<lite-youtube class="young-head__video" videoid="PpnJh-LZFMw" videoplay="Voir" videotitle="Les règles c'est sale ?" posterquality="maxresdefault" autopause>
					<a href="https://www.youtube.com/watch?v=PpnJh-LZFMw">Voir sur YouTube: "Les règles c'est sale ?"</a>
				</lite-youtube>
				<div className="young-head__video-desc-container">
					<p className="young-head__video-title">
					« Les règles, c’est sale » : un cliché vieux comme l’histoire
					</p>
					<div className="young-head__tag-list-container">
						<button type="button" className="young-head__tag focused" onClick={() => goToAllOnPeriodsWithTag("Tabou")}>#Tabou</button>
					</div>
				</div>
			</div>
			
		</div>
	);
}

export default YoungHeader;
