import { useNavigate } from "react-router";
import React, { useState, useEffect } from "react";
import useAxios from "../Hook/useAxios";
import { FaChevronRight } from "react-icons/fa6";
import PropTypes from "prop-types";

function Partner({ id }) {
	const [partner, setPartner] = useState("");
	const partnerData = useAxios(`/partners/${id}?populate=*`);

	useEffect(() => {
		if (partnerData?.data) {
			const newPartner = {
				logo: partnerData.data.data.attributes.logo.data.attributes.url,
				name: partnerData.data.data.attributes.name,
				url: partnerData.data.data.attributes.url,
			};
			setPartner(newPartner);
		}
	}, [partnerData]);

	return (
		<a
			className="focused"
			href={partner.url}
			target="_blank"
			rel="noreferrer"
		>
			<img
				className="partners-footer__img"
				width="100px"
				src={partner.logo}
				alt={partner.name}
			/>
		</a>
	);
}
Partner.propTypes = {
	id: PropTypes.number.isRequired,
};

function PartnersLogo() {
	return (
		<div className="partners-footer__img-container">
			{[5, 2, 3].map((id) => (
				<Partner key={id} id={id} />
			))}
		</div>
	);
}

function PartnersFooter() {
	const navigate = useNavigate();

	const routeChange = () => {
		let path = `/Partenaires`;
		navigate(path);
	};

	return (
		<div className="partners-footer">
			<h2 className="partners-footer__title">
				Nos <strong>Partenaires</strong>
			</h2>
			<div className="partners-footer__row-container">
				<div className="partners-footer__logos-container">
					<PartnersLogo />
				</div>
				<div className="partners-footer__button-container">
					<button
						className="partners-footer__button focused"
						onClick={routeChange}
					>
						Découvrir nos partenaires
						<FaChevronRight className="partners-footer__chevron" />
					</button>
				</div>
			</div>
		</div>
	);
}

export default PartnersFooter;
