import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { FaChevronUp } from "react-icons/fa6";
import triangle from "../assets/images/faq_triangle.svg";
import Markdown from 'react-markdown'

function FaqDropdown({ question }) {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleOpen();
		}
	};

	return (
		<div className="faqlist__faq-container" onClick={() => handleOpen()}>
			<div
				onKeyDown={(e) => handleKeyDown(e)}
				tabIndex="0"
				className="faqlist__question-container focused"
			>
				<p className="faqlist__question">{question.question}</p>
				<FaChevronUp className={`faqlist__chevron ${isOpen ? "actif" : "inactif"}`} />
			</div>
			<AnimatePresence>
				{isOpen && (
						<motion.div
							initial={{ opacity: 0, y: -100 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -100 }}
							transition={{ duration: 0.2 }}
							onClick={() => handleOpen()}
							onKeyDown={(e) => handleKeyDown(e)}
							tabIndex="0"
							className="faqlist__answer-container focused"
						>
							<img
								className="faqlist__triangle"
								src={triangle}
								alt=""
								aria-hidden="true"
							/>
							<p className="faqlist__answer-text">
								<Markdown>{question.answer}</Markdown>
							</p>
						</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}

function FaqList({ listFaq, loadMore, parent }) {
	return (
		<>
			{/* Ignore chain optional expression */}
			{listFaq &&
				listFaq?.map((item) => {
					if (
						parent &&
						item.category.find((cat) => cat.name === "Parents")
					) {
						return <FaqDropdown key={item.id} question={item} />;
					} else if (
						!parent &&
						item.category.find((cat) => cat.name === "Jeune")
					) {
						return <FaqDropdown key={item.id} question={item} />;
					} else {
						return null;
					}
				})}
			{loadMore ? null : (
				<div className="faqlist__showMore-container">
					<Link to="/Jeunes/QuestionsUtilisateurs">
						<button
							type="button"
							className="faqlist__showMore-button"
						>
							Voir plus de questions
						</button>
					</Link>
				</div>
			)}
		</>
	);
}

export default FaqList;
