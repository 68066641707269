import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAxios from '../../Hook/useAxios';
import VideoContainer from "../../components/VideoContainer";
import SearchBar from "../../components/SearchBar";
import FaqList from "../../components/Faq";
import { useUser } from "../../Hook/useUser";
import searchBulle from "../../assets/images/bulle_cherche.png"
import { fuseData } from '../../utilities/Fuse';
import ChatbotPopup from "../../components/ChatbotPopup";

function Result() {
  const [DataVideo, setDataVideo] = useState('');
  const [DataFaq, setDataFaq] = useState('');
  const AllVideo = useAxios('/videos?populate=*');
  const allFaq = useAxios('/testimonies?populate=*');
  const location = useLocation();
  const {setModalQuestionIsOpen } = useUser();

  useEffect(() => {
    // set page title
    document.title = 'Recherche - Parlons Règles';
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
		if (metaDescriptionTag) {
			metaDescriptionTag.setAttribute('content', "Résultats de ta recherche sur les règles.");
		}

    const getDataVideo = () => {
      const InputUser = location.state.userInput;
      if (AllVideo) {
        const VideosData = AllVideo.data.data.map(item => ({
          title: item.attributes.title,
          url: item.attributes.url,
          desc: item.attributes.description,
          category : item.attributes.categories.data.map(category => ({
            name: category.attributes.name,
            visible : category.attributes.visible
          }))
        }));

        const data = fuseData(VideosData, InputUser);
        setDataVideo(data);
      }
    };
    
    const filteredFaq = () => {
      const inputUser = location.state.userInput;
      if (allFaq) {
        const faqItems = allFaq.data.data.map(item => ({
          id: item.id,
          question: item.attributes.question,
          answer: item.attributes.answer,
          category : item.attributes.categories.data.map(category => ({
            name: category.attributes.name,
            visible : category.attributes.visible
          }))
        }));

        const data = fuseData(faqItems, inputUser);
        setDataFaq(data)
      }
    };
    getDataVideo();
    filteredFaq();
  }, [AllVideo, allFaq, location.state.userInput]);

  return (
    <>
      <div className="result">
        <div className="result__header-container">
          <p className="result__header-title">Ta recherche :</p><br />
          <h1 className="result__header-question">{location.state.userInput}</h1>
        </div>
      </div>
      {DataVideo.length !== 0 ? (
        <div className="resultVideos">
          <p className="resultVideos__title">Nos videos sur le <b><i>sujet</i></b></p>
          <VideoContainer ListVideo={DataVideo} />
        </div>
      ) : "" }
      {DataFaq.length !== 0 ? (
          <div className="result__faq-container">
            <p className="resultVideos__title-faq">Questions <b><i>déjà posées</i></b></p>
            <FaqList
              loadMore={false}
              filter={false}
              listFaq={DataFaq}
              parent={false}
              title={<p className="faqlist__title ">Questions <strong>Déjà posées</strong></p>} />
          </div>
          ) : ""
        }
      {DataVideo.length === 0 && DataFaq.length === 0 ? (
        <p className="result__no-result">Nous n'avons pas trouvé de réponse</p>
      ) : ""}
      <div className="result__searchbar-container">
        <img src={searchBulle} alt="Tu cherches autre chose ?" />
        <SearchBar />
        <p className="result__searchbar-title">Tu n'as pas trouvé l'information que tu cherchais ?</p>
        <button onClick={() =>
								setModalQuestionIsOpen({
									formModal: true,
									thanksModal: false,
								})
							} className="result__send-question-button" type="button">J'envoie ma question à l'équipe</button>
      </div>
      <ChatbotPopup />
    </>

  );
}

export default Result;
