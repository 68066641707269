import { useEffect, useState } from 'react';
import axios from 'axios';

const useAxios = (route) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `https://admin.parlonsregles.fr/api${route}`;
      const axiosConfig = {
        headers: {
          Authorization: `Bearer fadf5567ec16976a942a54abf71ab925fcef1080c66feb4db64ef7b5c06d582b02408ca81c159b41ff062da6c576b3198605f24d81ebc572f851c7e8d25d32b6eb8f7045898c468b1db7fb204592e9f1bbcd0bf600d3efa21f1f0331ac45158bd3901b3840efb1fb8f2ff6e0a47467259eb7edb73ee07dccf3e6cab0989a8461`,
        },
      };
      const response = await axios.get(apiUrl, axiosConfig);
      if (response?.data) {
        setData(response);
      }
    };
    fetchData();
  }, [route]);

  return data;
};

export default useAxios;
