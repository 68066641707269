import React, { useState } from "react";
import useAxios from "../Hook/useAxios";
import axios from "axios";
import { MdOutlineStar } from "react-icons/md";
import { useUser } from '../Hook/useUser';

function RatingArticle() {
	const [userRating, setUserRating] = useState(0);
	const allReview = useAxios("/reviews?populate=*");
    const { user } = useUser();

	const stars = [1, 2, 3, 4, 5];

	const starClic = (rate) => {
		setUserRating(rate);
        const axiosConfig = {
			headers: {
				Authorization: `Bearer fadf5567ec16976a942a54abf71ab925fcef1080c66feb4db64ef7b5c06d582b02408ca81c159b41ff062da6c576b3198605f24d81ebc572f851c7e8d25d32b6eb8f7045898c468b1db7fb204592e9f1bbcd0bf600d3efa21f1f0331ac45158bd3901b3840efb1fb8f2ff6e0a47467259eb7edb73ee07dccf3e6cab0989a8461`,
			},
		};
        const url = user === "parent" ? `https://admin.parlonsregles.fr/api/reviews-parents/${rate}` : `https://admin.parlonsregles.fr/api/reviews-relais/${rate}`;
        console.log(url);
        const data = {"data" : {
            "rating" : rate,
            "count" : allReview.data.data[rate - 1].attributes.count + 1
        }}
        axios
            .put(url, data, axiosConfig)
            .then((response) => {
                console.log("Réponse de l'API:", response.data);
            })
            .catch((error) => {
                console.error("Erreur lors de la requête PUT:", error);
            });
	};


	return (
		<div className="notation">
            <div className="notation__modal-container">
                <p className="notation__question">Qu'avez-vous pensé de cet article ?</p>
                <div className="notation__star-container">
                    {stars.map((star, index) => {
                        return (
                            <MdOutlineStar
                                key={star}
                                className={`notation__star ${
                                    userRating >= index + 1
                                        ? "notation__star--active"
                                        : "notation__star--inactif"
                                }`}
                                onClick={() => starClic(star)}
                            />
                        );
                    })}
                </div>
            </div>	
		</div>
	);
}

export default RatingArticle;
