import { usePref } from '../Hook/usePref';
import { orderList } from './Order';

const SpecificIds = (listData) => {
    const pref = usePref();
    const tags = pref.pref.tags;
    const pushFirstData = listData.filter(data => data.push_first === true);
    const restData = listData.filter(data => data.push_first === false);

    if (tags.length === 0) {
        const filteredId = [...pushFirstData, ...restData];
        return filteredId;
    } else {
        const orderedRestData = orderList(listData, tags);
        const filteredId = [...orderedRestData];
        return filteredId;
    }
};

export { SpecificIds };
