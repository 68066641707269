import { useState, useEffect } from "react";
import useAxios from "../Hook/useAxios";
import { FaChevronRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo_swapArticle.png";
import close from "../assets/images/close_swap_parent.svg";
import readIco from "../assets/images/read_ico.svg";
import { AnimatePresence, motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { useUser } from '../Hook/useUser';

function SwapArticle({locate}) {
	const navigate = useNavigate();
	const [index, setIndex] = useState(0);
	const [dataArticle, setDataArticle] = useState(null);
	const [visible, setVisible] = useState(true);
	const allArticles = useAxios("/articles?populate=*");
	const { user } = useUser();

	const goToAllArticleWithTag = (value) => {
		navigate("/Parents/AllArticles", { state: { tag: value} });
	};

	const nextArticle = () => {
		dataArticle !== null && index === dataArticle.length - 1
			? setIndex(0)
			: setIndex(index + 1);
		setVisible(false);
		setTimeout(() => {
			setVisible(true);
		}, "500");
	};

	useEffect(() => {
		const getDataArticle = () => {
			if (allArticles) {
				const articles = allArticles.data.data.map(
					(article) => article
				);
				// Keep only Parents articles
				let filteredArticles = articles.filter(article => {
					return article.attributes.categories.data.some(cat => {
						return cat.attributes.name === locate;
					});
				});
				setDataArticle(filteredArticles);
			}
		};
		getDataArticle();
	}, [allArticles]);

	const handleSwipe = () => {
		nextArticle();
	}

	const swipeHandlers = useSwipeable({
		onSwipedLeft: handleSwipe,
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
		delta: 50
	  });

	return (
		<div className="swapArticle">
			<div className="swapArticle__head-mobile">
				{user === "parent" && (
					<>
						<h2 className="swapArticle__head-title">					
							Le blog <br />
							<strong>
								<i> des règles</i>
							</strong>					
						</h2>
						<img className="swapArticle__head-illu" src={logo} alt="" aria-hidden="true" />
					</>
				)}
				{user === "relai" && (
					<h2 className="swapArticle__head-title">					
						<strong>Ressources</strong>			
					</h2>
				)}				
			</div>
			<div className="swapArticle__aside-container">
				<div className="swapArticle__aside-desc-container">
					{user === "parent" && (
						<>
							<h2 className="swapArticle__aside-title">
								Le blog <br />
								<strong>
									<i> des règles</i>
								</strong>
							</h2>
							<p className="swapArticle__aside-description">
								Par où commencer ? Le sujet est vaste et peut faire un peu peur aux premiers abords… Pas de panique, nous avons sans doute un article qui répondra à vos interrogations.
							</p>
							<Link to="/Parents/AllArticles" className="focused">
								<button className="swapArticle__aside-button" type="button">
									Voir tous les articles
									<FaChevronRight className="swapArticle__aside-button-ico" />
								</button>
							</Link>
						</>
					)}
					{user === "relai" && (
						<>
							<h2 className="swapArticle__aside-title">
								<strong>Ressources</strong>
							</h2>
							<p className="swapArticle__aside-description">
								Comment aborder le sujet dans mon établissement, avec mes collègues, avec les élèves… On vous a préparé pas mal de ressources pour vous lancer sans aucun tabou !
							</p>
							<Link to="/Relais/Ressources" className="focused">
								<button className="swapArticle__aside-button" type="button">
									Voir tous les articles
									<FaChevronRight className="swapArticle__aside-button-ico" />
								</button>
							</Link>
						</>
					)}					
					
				</div>
				<div className="swapArticle__aside-logo-container-desktop">
					<img
						className="swapArticle__aside-logo-desktop"
						src={logo}
						alt=""
						aria-hidden="true"
					/>
				</div>
			</div>
			{dataArticle && (
				<div className="swapArticle__card-container letter">
					<AnimatePresence>
						{visible && (
							<motion.div
								{...swipeHandlers}
								initial={{ opacity: 0 }}
								animate={{
									opacity: 1,
									x: 0,
									rotate: 0,
								}}
								exit={{ opacity: 0, x: -500, rotate: -25 }}
								transition={{ duration: 0.4, type: "bounce" }}
							>
								<div className="swapArticle__img-container">
									<img
										src={
											dataArticle[index].attributes
												.header_image.data.attributes
												.url
										}
										alt="icone de fermeture"
									/>
								</div>
								<div className="swapArticle__tag-container">
									{dataArticle[
										index
									].attributes.categories.data.map(
										(tag) => {
											return (
												<button type="button"
													onClick={() => goToAllArticleWithTag(tag.attributes.name)}
													key={tag.id}
													className="swapArticle__tag focused"
													style={{
														display: tag.attributes.visible
															? "inline-block"
															: "none",
													}}
												>
													#{tag.attributes.name}
												</button>
											);
										}
									)}
								</div>
								<div className="swapArticle__description-container">
									<p className="swapArticle__description-title">
										{dataArticle[index].attributes.title}
									</p>
									<div className="swapArticle__description" dangerouslySetInnerHTML={{ __html: dataArticle[
											index
										].attributes.content.substring(0, 300) + ' (...)'}}>
									</div>
								</div>
								<div className="swapArticle__row-button-container">
									<div
										onKeyDown={(e) =>
											e.key === "Enter"
												? nextArticle()
												: ""
										}
										tabIndex="0"
										onClick={nextArticle}
										className="swapArticle__button-container focused"
									>
										<img
											className="swapArticle__cross-ico"
											src={close}
											alt="icone de fermeture"
										/>
										<p className="swapArticle__next-button">
											Voir un autre article
										</p>
									</div>
									<Link
										to={`/article/${dataArticle[index].attributes.title.replace(/\s+/g, '-')}/${dataArticle[index].id}`} 
										state={{article: dataArticle[index]}}
										tabIndex="0"
										className="swapArticle__button-container focused"
									>
										<img
											className="swapArticle__play-ico"
											src={readIco}
											alt=""
											aria-hidden="true"
										/>
										<p className="swapArticle__next-button">
											Lire l'article
										</p>
									</Link>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
			{user === "parent" && (
			<Link to="/Parents/AllArticles">
				<button className="swapArticle__mobile-button" type="button">
					Voir tous les articles
					<FaChevronRight className="swapVideo__right-chevron-mobile" />
				</button>
			</Link>
			)}
			{user === "relai" && (
			<Link to="/Relais/Ressources">
				<button className="swapArticle__mobile-button" type="button">
					Voir tous les articles
					<FaChevronRight className="swapVideo__right-chevron-mobile" />
				</button>
			</Link>
			)}
		</div>
	);
}

export default SwapArticle;
