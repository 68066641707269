import React, { useEffect } from "react";
import Partners from "../../components/PartnersFooter";
import HomeQuestion from "../../components/HomeQuestion";
import TiktokHome from "../../components/TiktokHome";
import SwapVideo from "../../components/SwapVideo";
import SendQuestionContainer from "../../components/SendQuestionContainer";
import YoungHeader from "../../components/YoungHeader";
import ChatbotPopup from "../../components/ChatbotPopup";

function HomeYoung() {

	useEffect(() => {
		// set page title
		document.title = 'Accueil - Parlons Règles';

		const metaDescriptionTag = document.querySelector('meta[name="description"]');
		if (metaDescriptionTag) {
			metaDescriptionTag.setAttribute('content', "Les règles ce n'est ni mystérieux, ni tabou, c'est complètement naturel ! Ici on en parle sans jugement, et on se dit tout !");
		}
	}, []);

	return (
		<div className="homeYoung">
			<YoungHeader />
			<SwapVideo />
      		<TiktokHome />
			<HomeQuestion />
			<div className="homeYoung__send-question-container">
				<SendQuestionContainer />
			</div>
			<Partners />
			<ChatbotPopup />
		</div>
	);
}

export default HomeYoung;
