import React, { useEffect } from "react";
import HomeQuestion from "../../components/HomeQuestion";
import SwapArticle from "../../components/SwapArticle";
import HeaderParent from "../../components/HeaderParent";
import PartnersFooter from "../../components/PartnersFooter";

function HomeAdults() {

	useEffect(() => {
		// set page title
		document.title = 'Accueil Parents - Parlons Règles';
		const metaDescriptionTag = document.querySelector('meta[name="description"]');
		if (metaDescriptionTag) {
			metaDescriptionTag.setAttribute('content', "Pas facile d’aborder le sujet des règles avec ses enfants… Quel est le bon moment ? À quel âge je peux commencer à en parler ? Avec quels mots ? Cette plateforme est conçue pour donner de l’information aux plus jeunes mais aussi pour accompagner les parents qui peuvent se poser autant de questions que leurs enfants !");
		}
	}, []);

	return (
		<div className="HomeAdults">
			<HeaderParent />
			<SwapArticle locate="Parents" />
			<HomeQuestion />
			<PartnersFooter />
		</div>
	);
}

export default HomeAdults;