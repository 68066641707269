import Fuse from 'fuse.js';

const fuseData = (List, inputUser) => {
    const fuseOptions = {
        threshold: 0.3,
        ignoreLocation: true,
        keys: [
            'answer',
            'desc',
            'question',
            "category.name",
            "title",
        ]
    };
    const fuse = new Fuse(List, fuseOptions);
    const results = fuse.search(inputUser);
    
    // Remapping the results to the desired format
    const formattedResults = results.map(result => result.item);
    return formattedResults;
};

export { fuseData };
