import React, { useEffect } from "react";

function NotFound() {

	useEffect(() => {
		// set page title
		document.title = '404 - Parlons Règles';

		const metaDescriptionTag = document.querySelector('meta[name="description"]');
		if (metaDescriptionTag) {
			metaDescriptionTag.setAttribute('content', "404 not found");
		}
	}, []);

	return (
		<div className="notFound">
			<h1>404 Not Found</h1>
            <p>Oups ! La page à laquelle vous essayez d'accéder n'existe pas.</p>
		</div>
	);
}

export default NotFound;
