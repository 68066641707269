import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../Hook/useAxios";
import { useUser } from '../../Hook/useUser';
import RatingArticle from "../../components/RatingArticle";

function SpecificArticles() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [article, setArticle] = useState(null);
	// on requête allArticles car l'article ciblé ne renvoies pas toutes les infos (header image par exemple)
	// Dans le useEffect grace a l'id dans le useParam on viens trouver le bon article et on reformate l'object pour l'affichage
	const allArticles = useAxios("/articles?populate=*");
	const { user } = useUser();

	useEffect(() => {
		let title = "";
		let metaDescription = "";

		// function to extract text from html (needed for meta description)
		function extractContent(s) {
			var span = document.createElement('span');
			span.innerHTML = s;
			return span.textContent || span.innerText;
		};

		const saveArticle = () => {
			if (allArticles) {
				const article = allArticles.data.data.filter(
					(article) => article.id === Number(id)
				);
				const newArticle = article.map((item) => ({
					id: item.id,
					push_first: item.attributes.push_first,
					content: item.attributes.content,
					createdAt: item.attributes.createdAt,
					publishedAt: item.attributes.publishedAt,
					title: item.attributes.title,
					header: item.attributes.header_image.data.attributes.url,
					category: item.attributes.categories.data.map(
						(category) => ({
							name: category.attributes.name,
							visible: category.attributes.visible,
						})
					),
				}));
				setArticle(newArticle);
				title = newArticle[0].title;
				metaDescription =  extractContent(newArticle[0].content).substring(0, 150) + ' (...)';
			}			
		};
		saveArticle();

		// set page title
		document.title = title + ' - Parlons Règles';
		const metaDescriptionTag = document.querySelector('meta[name="description"]');
		if (metaDescriptionTag) {
			metaDescriptionTag.setAttribute('content', metaDescription);
		}

	}, [allArticles, id]);

	const handleClick = () => {
		if (user === "parent") {
			navigate("/Parents/AllArticles");
		} else {
			navigate("/Relais/Ressources");
		}
	};

	return (
		<div className="specificArticle">
			{article && (
				<>
					<img
						className="specificArticle__header"
						src={article[0].header}
						alt={article[0].title}
					/>
					<div className="specificArticle__title-container">
						<h2 className="specificArticle__title">
							{article[0].title}
						</h2>
					</div>
					<div className="specificArticle__container-categories">
						{article[0].category.map(
							(cat, index) =>
								cat.visible && (
									<span
										className="specificArticle__categories"
										key={index}
									>
										#{cat.name}
									</span>
								)
						)}
					</div>
					<div className="specificArticle__content" dangerouslySetInnerHTML={{ __html: article[0].content}}>
					</div>
					<RatingArticle />
					<button
						className="specificArticle__button"
						onClick={handleClick}
					>
						Voir tous les articles
					</button>
				</>
			)}
		</div>
	);
}

export default SpecificArticles;
